import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure, Input } from "@nextui-org/react";
import { RenterChip } from "../../chips/renterChip";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { FaIdBadge, FaUser } from "react-icons/fa6";
import { useCallback, useState } from "react";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import useErrorHandling from "../../../../hooks/useErrorHandling";

export function TransferOwnershipModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const renterId = useAppSelector(state => state.location.location.id);
  const [stage, setStage] = useState<"selection" | "id" | "username">("selection");
  const [id, setId] = useState<string>("");
  const handleError = useErrorHandling();

  const transfer = useCallback(() => {
    api.renters.transferOwnership(renterId, id)
      .then(_res => {
        toast.success("Компания успешно перенесена!");
        location.href = "/dashboard/selectLocation";
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      })
  }, [id]);

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      portalContainer={document.getElementById("main")!}
      backdrop="blur"
      scrollBehavior="outside"
    >
      <ModalContent>
        {(_onClose) => (
          <>
            <ModalHeader>
              <span>Передача владения компанией</span>
            </ModalHeader>
            <ModalBody>
              {stage === "selection" && (
                <div className="flex flex-col gap-2">
                  <span>Вы собираетесь передать права владения вашей компанией <RenterChip renterId={renterId} /></span>
                  <span>Эту операцию невозможно отменить - в случае ошибки вам придётся связываться с технической поддержкой</span>
                  <span>Выберите ниже способ передачи для продолжения</span>
                </div>
              )}
              {stage === "id" && (
                <div className="flex flex-col gap-4">
                  <span>Введите ID пользователя, которому хотите передать права:</span>
                  <Input
                    placeholder="Введите ID пользователя"
                    variant="bordered"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              {stage === "selection" && (
                <div className="w-full flex flex-row gap-2">
                  <Button
                    color="primary"
                    variant="flat"
                    className="w-full"
                    startContent={<FaIdBadge />}
                    onClick={() => setStage("id")}
                  >
                    По ID пользователя
                  </Button>
                  <Button
                    color="primary"
                    variant="flat"
                    className="w-full"
                    startContent={<FaUser />}
                    onClick={() => {}}
                    disabled
                  >
                    По логину пользователя
                  </Button>
                </div>
              )}
              {stage === "id" && (
                <div className="w-full flex flex-row gap-2">
                  <Button
                    color="primary"
                    variant="flat"
                    className="w-full"
                    onClick={() => setStage("selection")}
                  >
                    Назад
                  </Button>
                  <Button
                    color="success"
                    variant="flat"
                    onClick={transfer}
                    className="w-full"
                    isDisabled={!id} // Disable if ID is empty
                  >
                    Подтвердить передачу
                  </Button>
                </div>
              )}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
