import { useDisclosure } from "@nextui-org/react";
import { ReactNode } from "react";
import { ClientPermissions } from "../../../api/auth/auth";

export enum ColumnType {
  Date = "date",
  DateTime = "datetime",
  String = "string",
  Number = "number",
  Boolean = "boolean",
  User = "user",
  Renter = "renter",
  OfficeType = "office_type",
  Location = "location",
  Category = "category",
  Office = "office",
  Actions = "actions",
  Custom = "custom"
}

export interface TableAction {
  icon: ReactNode;
  onClick: (value: any, row: any) => void;
}

export interface BaseColumn {
  key: string;
  label: string;
  sortable?: boolean;
  render?: (value: any, row: any) => any;
  type?: ColumnType;
  permissions?: ClientPermissions[];
}

export interface ActionsColumn extends BaseColumn {
  type: ColumnType.Actions,
  actions: TableAction[]
}

export type Column = BaseColumn | ActionsColumn;

export interface TableBuilderProps {
  rowsPerPage?: number;
  columns: Column[];
  data: any[];
  isFullScreen?: boolean;
  fullscreenDisclosure?: ReturnType<typeof useDisclosure>
  displayOptions?: boolean;
  displaySearch?: boolean;
  removeWrapper?: boolean;
  defaultSortDescriptor?: SortDescriptor;
}

export type SortDescriptor = {
  column: string;
  direction: 'ascending' | 'descending';
};