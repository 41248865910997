import { Button, Card, Link } from "@nextui-org/react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, ReactNode } from "react";
import { FaArrowRight, FaDoorClosed } from "react-icons/fa6";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { OfficeLocation } from "../../../../api/locations/locations";
import { TableBuilder } from "../../../components/tables/tableBuilder";
import { SharedLock as SharedLockType } from "../../../../api/sharedLocks/sharedLocks";
import { Office } from "../../../../api/officies/officies";
import { SharedLock } from "../../../components/lock/sharedLock";
import { Lock } from "../../../components/lock/lock";
import { Ticket } from "../../../../api/tickets/tickets";
import { sortAlphabeticallyByKey } from "../../../../utils";
import { ColumnType } from "../../../components/tables/tableTypes";

function SuspenseMd({ children, suspend }: { children: ReactNode, suspend: ReactNode }) {
  return (
    <>
      <div className="max-md:hidden">
        {children}
      </div>
      <div className="md:hidden">
        {suspend}
      </div>
    </>
  )
}

export function EmployeePage() {
  const user = useAppSelector(state => state.user);
  const org = useAppSelector(state => state.location.location);
  const [locations, setLocations] = useState<OfficeLocation[]>([]);
  const navigate = useNavigate();
  const [offices, setOffices] = useState<Office[]>([]);
  const [sharedLocks, setSharedLocks] = useState<SharedLockType[]>([]);
  const [tickets, setTickets] = useState<Ticket[] | null>(null);

  useEffect(() => {
    if (org.id == 0) return;

    api.locations.list()
      .then(data => {
        setLocations(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке локаций!");
      });

    api.officies.list()
      .then(data => {
        const sortedData = sortAlphabeticallyByKey(data.data, "display_name");
        setOffices(sortedData);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });

    api.sharedLocks.get()
      .then(data => {
        const sortedData = sortAlphabeticallyByKey(data.data, "display_name");
        setSharedLocks(sortedData);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке общих замков!");
      });

    api.tickets.list()
      .then(response => {
        setTickets(response.data);
      })
      .catch(err => {
        toast.error("Произошла ошибке при загрузке тикетов!");
        console.log(err);
      });
  }, []);

  // TODO: все блоки нужно перенести в reusable widgets

  return (
    <div className="w-full h-full flex flex-col-reverse md:flex-row gap-4 p-2 md:p-4">
      <div className="flex flex-row flex-grow gap-2">
        <div className="flex flex-col flex-grow gap-2">
          <div className="hidden md:flex flex-col mb-2 gap-2">
            <span className="font-bold text-2xl">Доброго времени суток, {user.currentUser.first_name}!</span>
          </div>

          <div className="flex flex-col mb-2 gap-2">
            <div className="flex flex-row gap-2">
              <div className="flex flex-col mb-2 mt-2 gap-4 flex-grow">
                <SuspenseMd
                  suspend={<Button className="w-full" as={Link} href="/dashboard/locations" variant="solid" color="primary">Просмотреть локации</Button>}>
                  <div className="flex flex-row gap-2 items-center mb-2">
                    <span className="font-semibold text-xl">Локации</span>
                  </div>
                  <TableBuilder
                    displayOptions={false}
                    displaySearch={false}
                    columns={[
                      {
                        label: "Название",
                        key: "display_name",
                        type: ColumnType.String
                      }, {
                        label: "Адрес",
                        key: "address",
                        type: ColumnType.String
                      }, {
                        label: "Город",
                        key: "city",
                        type: ColumnType.String
                      }, {
                        label: "Станция метро",
                        key: "metro_station",
                        type: ColumnType.String
                      }, {
                        label: "Район",
                        key: "district",
                        type: ColumnType.String
                      }, {
                        key: "actions",
                        label: "Действия",
                        type: ColumnType.Actions,
                        actions: [
                          {
                            icon: <FaDoorClosed />,
                            onClick: (_value, row) => { navigate(`/dashboard/locations/${row.id}/offices`) }
                          }
                        ]
                      }
                    ]}
                    data={locations}
                  />
                </SuspenseMd>
                <SuspenseMd
                  suspend={<Button className="w-full" as={Link} href="/dashboard/tickets" variant="solid" color="primary">Просмотреть обращения</Button>}>
                  <div className="flex flex-col mt-2 mb-2 gap-2">
                    <div className="flex flex-row gap-2 items-center">
                      <span className="font-semibold text-xl">Обращения</span>
                    </div>
                    <TableBuilder
                      displayOptions={false}
                      displaySearch={false}
                      columns={[{
                        key: "title",
                        label: "Тема обращения",
                        type: ColumnType.String
                      }, {
                        key: "status",
                        label: "Статус",
                        type: ColumnType.Custom,
                        sortable: true,
                        render(value, _row) {
                          return (<span>{value == "open" ? 'Открыт' : 'Закрыт'}</span>)
                        },
                      }, {
                        key: "category",
                        label: "Категория",
                        type: ColumnType.Category,
                        sortable: true
                      }, {
                        key: "office",
                        label: "Офис",
                        type: ColumnType.Office
                      }, {
                        key: "created_at",
                        label: "Создан",
                        type: ColumnType.DateTime,
                        sortable: true
                      }, {
                        key: "updated_at",
                        label: "Обновлён",
                        type: ColumnType.DateTime,
                        sortable: true
                      }, {
                        key: "actions",
                        label: "Действия",
                        type: ColumnType.Actions,
                        actions: [
                          {
                            icon: <FaArrowRight />,
                            onClick: (_value, row) => { navigate(`/dashboard/tickets/${row.id}`) }
                          }
                        ]
                      }]}
                      data={tickets || []}
                    />
                  </div>
                </SuspenseMd>
              </div>

              {sharedLocks.length > 0 || offices.length > 0 ? (
                <div className="max-h-fit max-md:hidden md:p-2 p-4">
                  <div className="flex flex-row flex-wrap gap-2">
                    {sharedLocks.map((lock) => (
                      <SharedLock key={lock.id} lock={{ id: lock.id, displayName: lock.display_name }} />
                    ))}
                    {offices.map((office) => (
                      <Lock key={office.id} lock={{ id: office.id, displayName: office.display_name }} />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col md:hidden mb-2 gap-2">
          <span className="font-bold text-2xl">Доброго времени суток, {user.currentUser.first_name}!</span>
          <span className="font-medium text-xl">
            Вы - {user.permissions.role} в <span className="text-primary-500">{org.display_name}.</span>
          </span>
        </div>
        {sharedLocks.length > 0 || offices.length > 0 ? (
          <Card className="max-h-fit md:p-2 p-4 md:hidden">
            <div className="flex flex-row flex-wrap gap-2">
              {sharedLocks.map((lock) => (
                <SharedLock key={lock.id} lock={{ id: lock.id, displayName: lock.display_name }} />
              ))}
              {offices.map((office) => (
                <Lock key={office.id} lock={{ id: office.id, displayName: office.display_name }} />
              ))}
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
}