import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";
import { Button, Input, Link } from "@nextui-org/react";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";

interface PasswordRecoveryPromptForm {
  code: string;
  password: string;
}

export function PasswordRecoveryVerify() {
  const navigate = useNavigate();
  const { username } = useParams() as { username: string };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordRecoveryPromptForm>({
    defaultValues: {},
  });

  const resetPassword = (data: any) => {
    api.auth.verifyReset({ username, code: data.code, new_password: data.password })
      .then(_data => {
        toast.success("Пароль успешно восстановлен!");
        navigate("/");
      })
      .catch(err => {
        console.log(err);
        toast.error("Введён неверный код!");
      })
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-col items-center justify-center w-full h-screen bg-[url(/Bg.png)] bg-cover bg-center">
        <motion.div
          className="bg-white rounded-xl p-8 shadow flex flex-col gap-2 md:min-w-[515px]"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <form className="flex flex-col gap-2" onSubmit={handleSubmit(resetPassword)}>
            <h2>Восстановление пароля</h2>
            <Input
              label="Введите код"
              placeholder="Введите код"
              variant="bordered"
              type="text"
              {...register("code", { required: true })}
              className="mb-2"
              isInvalid={!!errors["code"]}
              errorMessage={errors["code"]?.message?.toString()}
            />
            <Input
              label="Введите новый пароль"
              placeholder="Введите новый пароль"
              variant="bordered"
              type="text"
              {...register("password", { required: true })}
              className="mb-2"
              isInvalid={!!errors["password"]}
              errorMessage={errors["password"]?.message?.toString()}
            />
            <div className="mt-4 flex gap-4">
              <Button color="primary" className="w-full font-semibold" type="submit">
                Восстановить
              </Button>
            </div>
            <div className="mt-4 flex w-full flex-col items-center">
              <Link href="/" className="font-medium" showAnchorIcon>Я вспомнил пароль</Link>
            </div>
          </form>
        </motion.div>
        {process.env.VITE_BUILD_BRANCH?.startsWith("dev") && (
          <motion.div
            className="flex flex-col items-center gap-2 mt-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <span className="text-sm text-zinc-600 font-medium">
              <a href={`https://github.com/Elasticwork-space/ElasticAdmin/commit/${process.env.VITE_COMMIT_HASH}`}>
                {process.env.VITE_COMMIT_HASH} / {process.env.VITE_BUILD_BRANCH}
              </a>
            </span>
          </motion.div>
        )}
        <br />
      </div>
    </>
  );
}
