import { useParams } from "react-router-dom";
import { FaArrowsLeftRight, FaPen } from "react-icons/fa6";
import { UserChip } from "../../../components/chips/userChip";
import { useDisclosure, Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { Renter } from "../../../../api/renters/renters";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { EditRenterModal } from "../../../components/modals/renters/editRenterModal";
import { TransferOwnershipModal } from "../../../components/modals/renters/transferOwnershipModal";
import { useAppSelector } from "../../../../hooks/useAppSelector";

export function RenterPage() {
  const { id } = useParams() as { id: string };
  const [renter, setRenter] = useState<Renter | null>(null);
  const user = useAppSelector(state => state.user.currentUser);

  const editRenterModalDisclosure = useDisclosure();
  const transferRenterModalDisclosure = useDisclosure();
  const handleError = useErrorHandling();

  useEffect(() => {
    api.renters.get(Number(id))
      .then(res => {
        setRenter(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [id]);

  return (
    <>
      <EditRenterModal disclosure={editRenterModalDisclosure} renter={renter} />
      <TransferOwnershipModal disclosure={transferRenterModalDisclosure} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  {renter?.display_name}
                </span>
                <div className="flex flex-row gap-2">
                  {renter?.holder == user.id ? (
                    <Button
                      variant="bordered"
                      startContent={<FaArrowsLeftRight />}
                      color="primary"
                      onClick={transferRenterModalDisclosure.onOpen}
                    >
                      Передать владение
                    </Button>
                  ) : null}
                  <Button
                    variant="bordered"
                    startContent={<FaPen />}
                    color="primary"
                    onClick={editRenterModalDisclosure.onOpen}
                  >
                    Редактировать
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Владелец</span>
                <span className="font-medium"><UserChip userId={renter?.holder || 0} /></span>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">ОГРН/ОГРНИП</span>
                <span className="font-medium">{renter?.orgn}</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Номер телефона</span>
                <span className="font-medium">{renter?.phone_number}</span>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Адрес электронной почты</span>
                <span className="font-medium">{renter?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}